import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'

import pdf from '../../assets/documents/GE_2019_COSMETOLOGY.pdf'

const pdfStyle = {
  width: '100%',
  height: '80vh',
}

const Employment = props => (
  <Layout>
    <Helmet>
      <title>Thanh Le College, School of Cosmetology - Gainful Employment</title>
      <meta name="description" content="Gainful Employment" />
    </Helmet>
    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Gainful Employment</h1>
          </header>
          <iframe
            src={pdf + '#view=fit'}
            width="100%"
            height="100%"
            style={pdfStyle}
            title="Gainful Employment"
          />
        </div>
      </section>
    </div>
  </Layout>
)

export default Employment
